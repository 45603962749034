import { scaleLinear, scaleBand } from 'd3-scale';
import { max, min } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import dayjs from 'dayjs';

const ranges = {
  '1D': { startOf: 'hour', format: 'h A' },
  YD: { startOf: 'hour', format: 'h A' },
  WTD: { startOf: 'day', format: 'ddd' },
  CW: { startOf: 'day', format: 'ddd' },
  LW: { startOf: 'day', format: 'ddd' },
  CM: { startOf: 'week', format: 'MMM D' },
  MTD: { startOf: 'week', format: 'MMM D' },
  LM: { startOf: 'week', format: 'MMM D' },
  '3M': { startOf: 'month', format: 'MMM D' },
  YTD: { startOf: 'month', format: 'MMM D' },
  '1Y': { startOf: 'month', format: 'MMM D' },
  CUSTOM: { startOf: 'week', format: 'MMM D' }
};

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 400
    },
    margin: {
      type: Object,
      default() {
        return {};
      }
    },
    scale: {
      type: Number,
      default: 1
    },
    xAxisKey: {
      type: String,
      default: 'labels'
    },
    yAxisKey: {
      type: String,
      default: 'values'
    },
    showAxis: {
      type: Boolean,
      default: true
    },
    strokeColor: {
      type: String || Function || Object,
      default: '#5072ff'
    },
    strokeWidth: {
      type: Number,
      default: 2
    },
    showOutline: {
      type: Boolean,
      default: true
    },
    fillColor: {
      type: String || Function || Object,
      default: '#5072ff'
    },
    animate: {
      type: Boolean,
      default: false
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    yAxisRange: {
      type: Array | Boolean,
      default: false
    },
    zeroScale: {
      type: Boolean,
      default: true
    },
    xAxisPadding: {
      type: Number,
      default: 0
    },
    xAxisGroup: {
      type: String,
      required: false,
      validator: (value) => ['hour', 'day', 'week', 'month', 'year'].includes(value)
    },
    xAxisFormat: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      baseMargin: this.showAxis ? { left: 30, right: 0, top: 5, bottom: 24 } : { left: 0, right: 0, top: 0, bottom: 0 },
      resetData: true,
      timeoutHandler: null,
      mouseData: { label: null, value: null, formattedValue: null },
      activeScale: scaleBand,
      svgBoundingRect: null,
      updatedTooltipContent: ''
    };
  },
  watch: {
    data: {
      immediate: false,
      handler() {
        this.handleUpdateChartEvent();
      }
    },
    windowWidth() {
      if (this.showTooltip) {
        this.svgBoundingRect = this.$refs.svg.getBoundingClientRect();
      }
    }
  },
  computed: {
    windowWidth() {
      return this.$store.getters.windowWidth;
    },
    x() {
      return this.activeScale()
        .domain(this.xData)
        .range([0, this.chartWidth])
        .padding(this.xAxisPadding);
    },
    y() {
      let domain;

      if (this.yAxisRange) {
        domain = this.yAxisRange;
      } else {
        domain = this.zeroScale
          ? [0, Math.max(max(this.yData) * 1.1, 5)]
          : [min(this.yData), Math.max(max(this.yData) * 1.1, min(this.yData) + 1)];
      }

      return scaleLinear()
        .range([this.chartHeight, 0])
        .domain(domain);
    },
    calculatedMargin() {
      return Object.assign(this.baseMargin, this.margin);
    },
    chartWidth() {
      return this.width - this.calculatedMargin.left - this.calculatedMargin.right;
    },
    chartHeight() {
      return this.height - this.calculatedMargin.top - this.calculatedMargin.bottom;
    },
    axisBottom() {
      if (!this.showAxis) return axisBottom(this.x).tickValues([]);

      const range = this.$store.getters.getMetricDateRange || 'CUSTOM';
      let ticks = this.x.domain();
      const maxRange = ticks.length >= 60 ? 'month' : (ticks.length <= 7 ? 'day' : undefined);

      if (ranges[range]) {
        ticks = ticks.filter(d => {
          return ranges[range].startOf === 'hour'
            ? (dayjs(d).format('H') % 4) === 0
            : dayjs(d).isSame(dayjs(d).startOf(this.xAxisGroup || maxRange || ranges[range].startOf));
        });
      }

      return axisBottom(this.x).tickValues(ticks).tickFormat(d => {
        return dayjs(d).format(this.xAxisFormat || ranges[range].format || 'MMM D');
      }).tickSize(0);
    },
    axisLeft() {
      return axisLeft(this.y).ticks(5, 's').tickSize(-this.chartWidth);
    },
    yData() {
      return this.data[this.yAxisKey];
    },
    xData() {
      return this.data[this.xAxisKey];
    },
    defaultTooltipContent() {
      if (this.mouseData.formattedValue === null) return null;
      const format = ranges[this.$store.getters.getMetricDateRange].format.replace(/(\w){3}/, '$1$1$1$1');
      return `<ul style="min-width: 60px">
        <li><strong>${this.mouseData.label ? dayjs(this.mouseData.label).format(format) : ' '}</strong></li>
        <li>${this.mouseData.formattedValue}</li>
      </ul>`;
    },
    tooltipContent() {
      return this.updatedTooltipContent ? this.updatedTooltipContent : this.defaultTooltipContent;
    },
    positionFunc() {
      return this.$el.getAttribute('class').includes('bar') ? Math.floor : Math.round;
    },
    mouseEvents() {
      return this.$supportsTouch ? ['touchmove', 'touchstart'] : ['mousemove'];
    }
  },
  mounted() {
    this.handleUpdateChartEvent();
    this.unbindMouseEvents();
    this.bindMouseListeners();
  },
  beforeDestroy() {
    this.unbindMouseEvents();
  },
  methods: {
    unbindMouseEvents() {
      this.mouseEvents.forEach((eventName) => {
        this.$el.removeEventListener(eventName, this.mouseListener, this.$supportsPassive && { passive: true });
      });
    },
    bindMouseListeners() {
      if (this.showTooltip) {
        this.svgBoundingRect = this.$refs.svg.getBoundingClientRect();
        this.mouseEvents.forEach((eventName) => {
          this.$el.addEventListener(eventName, this.mouseListener, this.$supportsPassive && { passive: true });
        });
      }
    },
    colorize(prop, options) {
      if (typeof this[prop] === 'string') {
        return this[prop];
      }
      if (typeof this[prop] === 'object') {
        return this[prop][options];
      }
      if (typeof this[prop] === 'function') {
        return this[prop](options);
      }
    },
    handleUpdateChartEvent() {
      this.resetData = true;
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = setTimeout(() => {
        this.resetData = false;
        if (typeof this.onChartUpdated === 'function') {
          this.onChartUpdated();
        }
        if (typeof this.onDataReset === 'function') {
          this.onDataReset();
        }
      }, 10);
    },
    mouseListener(e) {
      if (!this.svgBoundingRect) {
        return;
      }
      // In desktop, always get the latest getBoundingClientRect in case there was a layout change
      const svgBoundingRect = this.mouseEvents.includes('mousemove') ? this.$refs.svg.getBoundingClientRect() : this.svgBoundingRect;
      const scale = this.$el.offsetWidth / parseInt(this.$refs.svg.getAttribute('viewBox').split(' ')[2]);
      const x = (this.mouseEvents.includes('mousemove') ? e.clientX : e.touches[0].clientX) - svgBoundingRect.left - this.$el.clientLeft - (this.calculatedMargin.left * scale);
      const index = Math.max(Math.min(this.positionFunc(x / scale / this.x.step()), this.xData.length - 1), 0);
      const label = this.xData[index];
      const value = this.yData[index];

      let prev = null;
      if (index > 0) {
        prev = {
          label: this.xData[index - 1],
          value: this.yData[index - 1],
          formattedValue: this.data.formattedValues ? this.data.formattedValues[index - 1] : this.yData[index - 1]
        };
      };

      // Has fallback value if for some reason it did not format correctly
      const formattedValue = this.data.formattedValues ? this.data.formattedValues[index] : this.yData[index];

      Object.assign(this.mouseData, { label, value, formattedValue, prev });
      this.$emit('mouse-event-changed', this.mouseData);
    }
  }
};
