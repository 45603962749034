<template>
  <div
    v-tippy="{ enabled: showTooltip, content: tooltipContent, zIndex: 'auto' }"
    class="svg-cont">
    <svg
      ref="svg"
      :viewBox="`0 0 ${width*scale} ${height*scale}`"
      width="100%"
      height="100%"
      preserveAspectRatio="xMinYMin meet"
      class="line-chart">
      <defs>
        <linearGradient
          :id="_uid"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%">
          <stop :stop-color="colorize('fillColor')" offset="0" />
          <stop :stop-color="colorize('fillColor')" offset="15%" />
          <stop :stop-color="colorize('fillColor')" offset="33%" stop-opacity="0.88" />
          <stop :stop-color="colorize('fillColor')" offset="60%" stop-opacity="0.70" />
          <stop :stop-color="colorize('fillColor')" offset="80%" stop-opacity="0.45" />
          <stop :stop-color="colorize('fillColor')" offset="100%" stop-opacity="0" />
        </linearGradient>
      </defs>
      <g v-show="xData.length > 0" ref="dataSvg" :transform="'translate('+calculatedMargin.left+','+calculatedMargin.top+')'" class="line-chart__wrapper">
        <g v-if="showAxis" class="axis">
          <g ref="xAxis" :transform="'translate(0,'+chartHeight+')'" class="axis-x" />
          <g ref="yAxis" class="axis-y" />
        </g>
        <path
          v-if="showLine"
          ref="linePath"
          :key="`line-${path.line}`"
          :d="path.line"
          :stroke="colorize('strokeColor')"
          :stroke-width="strokeWidth"
          fill="none"
          shape-rendering="geometricPrecision"
          class="line-chart__line-path">
          <animate
            v-if="animate"
            :from="base.line"
            :to="path.line"
            attributeName="d"
            dur="0.4s"
            keyTimes="0; 1"
            calcMode="spline"
            keySplines="0.39, 0.575, 0.565, 1;"
            fill="freeze" />
        </path>
        <path
          v-if="showLine && showOutline"
          ref="linePath"
          :key="`outline-${path.line}`"
          :d="path.line"
          :stroke="colorize('strokeColor')"
          :stroke-width="Math.pow(strokeWidth, 4)"
          opacity="0.075"
          fill="none"
          shape-rendering="geometricPrecision"
          class="line-chart__line-path">
          <animate
            v-if="animate"
            :from="base.line"
            :to="path.line"
            attributeName="d"
            dur="0.4s"
            keyTimes="0; 1"
            calcMode="spline"
            keySplines="0.39, 0.575, 0.565, 1;"
            fill="freeze" />
        </path>
        <path
          v-if="showArea"
          ref="areaPath"
          :key="`area-${path.area}`"
          :fill="`url(#${_uid})`"
          :d="path.area"
          stroke-width="0"
          shape-rendering="geometricPrecision"
          class="line-chart__area-path">
          <animate
            v-if="animate"
            :from="base.area"
            :to="path.area"
            attributeName="d"
            dur="0.4s"
            keyTimes="0; 1"
            calcMode="spline"
            keySplines="0.39, 0.575, 0.565, 1;"
            fill="freeze" />
        </path>
        <path
          v-if="showTooltip"
          ref="mouseLine"
          :key="`mouse-line-${yData.length}`"
          class="line-chart__mouse-line"
          d=""
          stroke-dasharray="1.5"
          stroke-width="1"
          stroke="#525662" />
        <circle
          v-if="showTooltip && circlePos.x"
          :key="`mouse-circle-${_uid}`"
          :cx="circlePos.x"
          :cy="circlePos.y"
          r="4"
          :stroke-width="circleAttrs.strokeWidth"
          :stroke="circleAttrs.stroke"
          :fill="circleAttrs.fill" />
      </g>
    </svg>
  </div>
</template>

<script>
import { line, area, curveMonotoneX } from 'd3-shape';
import { select } from 'd3-selection';
import { scalePoint } from 'd3-scale';
import chartsMixin from './charts';

export default {
  name: 'LineChart',
  mixins: [chartsMixin],
  props: {
    showLine: {
      type: Boolean,
      default: true
    },
    showArea: {
      type: Boolean,
      default: false
    },
    areaColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.2)'
    },
    circleAttrs: {
      type: Object,
      default: () => ({ strokeWidth: 3, fill: 'transparent', stroke: '#333' })
    },
    rangeStart: {
      type: String,
      required: false,
      default: ''
    },
    rangeEnd: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      circlePos: { x: null, y: null },
      activeScale: scalePoint,
      rangeBar: undefined,
      rangeStartPoint: undefined,
      rangeEndPoint: undefined
    };
  },
  computed: {
    base() {
      return {
        area: this.baseArea(this.preparedData),
        line: this.baseLine(this.preparedData)
      };
    },
    path() {
      return {
        area: this.area(this.preparedData),
        line: this.line(this.preparedData)
      };
    },
    preparedData() {
      return this.xData.map((d, i) => {
        return {
          label: d,
          value: this.yData[i]
        };
      });
    },
    area() {
      return area()
        .x(d => {
          return this.x(d.label);
        })
        .y0(d => {
          return this.y(this.y.domain()[0]);
        })
        .y1(d => {
          return this.y(d.value);
        })
        .curve(curveMonotoneX);
    },
    baseArea() {
      return area()
        .x(d => {
          return this.x(d.label);
        })
        .y0(d => {
          return this.y(this.y.domain()[0]);
        })
        .y1(d => {
          return this.y(this.y.domain()[0]);
        })
        .curve(curveMonotoneX);
    },
    line() {
      return line()
        .x(d => {
          return this.x(d.label);
        })
        .y(d => {
          return this.y(d.value);
        })
        .curve(curveMonotoneX);
    },
    baseLine() {
      return line()
        .x(d => {
          return this.x(d.label);
        })
        .y(d => {
          return this.y(this.y.domain()[0]);
        })
        .curve(curveMonotoneX);
    },
    yData() {
      return this.resetData && this.animate
        ? new Array(this.data[this.yAxisKey].length).fill(0)
        : this.data[this.yAxisKey];
    },
    rangeEndComputed() {
      return this.rangeEnd || this.data.labels[this.data.labels.length - 1];
    }
  },
  watch: {
    'mouseData.label'(mouseData) {
      this.drawMouseLine(mouseData);
    }
  },
  destroyed() {
    select(this.$el).on('mousemove', null);
  },
  methods: {
    drawMouseLine() {
      if (this.mouseData.label === null) {
        this.$refs.mouseLine.removeAttribute('d');
      } else {
        Object.assign(this.circlePos, { x: this.x(this.mouseData.label), y: this.y(this.mouseData.value) });

        const domain = this.y.domain();
        this.$refs.mouseLine.setAttribute(
          'd',
          this.line([
            { label: this.mouseData.label, value: domain[0] },
            { label: this.mouseData.label, value: domain[1] }
          ])
        );
      }
    },
    onChartUpdated() {
      if (this.showAxis) {
        this.renderAxis();
      }
      if (this.rangeStart && this.rangeEnd) {
        this.drawRangeBar();
        setTimeout(this.drawRangePoints, 400); // wait for animation to complete before drawing points
      }
    },
    renderAxis() {
      select(this.$refs.xAxis).call(this.axisBottom);
      select(this.$refs.yAxis).call(this.axisLeft);
    },
    onDataReset() {
      this.circlePos.x = null;
    },
    drawRangeBar() {
      const rangeStartIndex = this.xData.indexOf(this.rangeStart);
      const rangeEndIndex = this.xData.indexOf(this.rangeEndComputed);

      // Invalid range passed
      if (rangeStartIndex === -1 || rangeEndIndex === -1) {
        return;
      }

      const startX = this.x(this.rangeStart);
      const endX = this.x(this.rangeEndComputed);

      if (!this.rangeBar) this.rangeBar = select(this.$refs.dataSvg).append('rect');
      const rectX = startX;
      const rectY = 0;
      const rectWidth = Math.abs(endX - rectX);
      this.rangeBar.attr('x', rectX)
        .attr('y', rectY)
        .attr('width', rectWidth)
        .attr('height', this.chartHeight)
        .attr('fill', 'rgb(246, 246, 249)')
        .lower();
    },
    drawRangePoints() {
      const rangeStartIndex = this.xData.indexOf(this.rangeStart);
      const rangeEndIndex = this.xData.indexOf(this.rangeEndComputed);

      // Invalid range passed
      if (rangeStartIndex === -1 || rangeEndIndex === -1) {
        return;
      }

      // start circle
      if (!this.rangeStartPoint) this.rangeStartPoint = select(this.$refs.dataSvg).append('circle');
      const startCX = this.x(this.rangeStart);
      const startCY = this.y(this.yData[rangeStartIndex]);
      this.rangeStartPoint.attr('cx', startCX)
        .attr('cy', startCY)
        .attr('r', '4')
        .attr('fill', '#9D67E4')
        .attr('stroke-width', '2')
        .attr('stroke', '#fff');

      // end circle
      if (!this.rangeEndPoint) this.rangeEndPoint = select(this.$refs.dataSvg).append('circle');
      const endCX = this.x(this.rangeEndComputed);
      const endCY = this.y(this.yData[rangeEndIndex]);
      this.rangeEndPoint.attr('cx', endCX)
        .attr('cy', endCY)
        .attr('r', '4')
        .attr('fill', '#9D67E4')
        .attr('stroke-width', '2')
        .attr('stroke', '#fff');
    }
  }
};
</script>
<style lang="scss">
.svg-cont {
  display: inline-block;
  position: relative;
  width: 100%;

  .line-chart {
    outline: transparent;
    user-select: none;

    .axis {
      text {
        fill: $--clb-body-font;
        font-size: 11px;
        -webkit-font-smoothing: antialiased;
      }

      .domain {
        stroke: transparent;
      }

      .tick {
        line {
          stroke: $--clb-disabled-color;
        }
      }

      .axis-x {
        text {
          font-size: 11px;
          text-anchor: middle;
          transform: translate(-5px, 6px) rotate(0deg);
          -webkit-font-smoothing: antialiased;
        }
      }
    }
  }
}

@include xs-only {
  .svg-cont {
    .line-chart {
      .axis {
        text,
        .axis-x text {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
